import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { FaArrowRight } from 'react-icons/fa';

import FeatureIcon1 from '../../images/features/multiple-popup-triggering/exit-intent-popup.png'
import FeatureIcon2 from '../../images/features/multiple-popup-triggering/device-based.png'
import FeatureIcon3 from '../../images/features/multiple-popup-triggering/page-based.png'
import FeatureIcon4 from '../../images/features/multiple-popup-triggering/delay.png'
import FeatureIcon5 from '../../images/features/multiple-popup-triggering/position-right.png'
import FeatureIcon6 from '../../images/features/multiple-popup-triggering/on-page-load.png'

export class HomePopupFeatures extends Component {
    render() {
        return (
            <>
                <div className="home-multi-popup-trigger-features-section pb-0 bg-light">
                    <div className="container">
                        <Row className="align-items-center text-center pt-3">
                            <Col md="12">
                                <div className="hero-content wow fadeIn">
                                    <h2>Start <span className="style-highlight"> Creating Popup
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span> Campaigns Now</h2>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="home-multi-popup-trigger-features-section">
                    <div className="container">
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon1} alt="" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4 ps-5 pe-3">
                                    <h3>Exit Intent Triggers</h3>
                                    <p>Stop abandonment today. Prevent visitors from abandoning your website/eCommerce store. Use Optinly’s exit trigger popup display rule to show popups when users try to exit. Optinly tracks the mouse movement of the user and executes the exit intent rule when needed.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4 ps-3 pe-5">
                                    <h3>Device-Based Triggers</h3>
                                    <p>Display popups only on devices you want to. Choose between devices like desktops or tablets or mobile and display popup campaigns on them. Choose to display on all devices or only on selected devices – the toggle switches make this job hassle-free.</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon2} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
                <div className="home-multi-popup-trigger-features-section">
                    <div className="container">
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon3} alt="" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4 ps-5 pe-3">
                                    <h3>Page-Based Triggers</h3>
                                    <p>Display popup campaigns on selected pages. Use Optinly’s URL-based popup display rules to show campaigns on pages you think might help you drive conversions. You can</p>
                                    <ul className="p-0">
                                        <li className="mb-1"><FaArrowRight className="text-primary me-2" /> Show popups on selected pages</li>
                                        <li className="mb-1"><FaArrowRight className="text-primary me-2" /> Exclude popups from being displayed on selected pages</li>
                                        <li className="mb-1"><FaArrowRight className="text-primary me-2" /> Display popups only on the homepage</li>
                                        <li className="mb-1"><FaArrowRight className="text-primary me-2" /> Display popups using specific URL queries</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center pt-5">
                            <Col md="6">
                                <div className="mb-4 ps-3 pe-5">
                                    <h3>Timed-Delay Triggers</h3>
                                    <p>Allow web audience to settle in and let them feel comfortable knowing your business. The more time they spend, the higher are the chances of conversion. Use timed delay triggers to delay the popup appearance on selected pages by manually entering the delay period.</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon4} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
                <div className="home-multi-popup-trigger-features-section">
                    <div className="container">
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon5} alt="" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4 ps-5 pe-3">
                                    <h3>Position Based Display Rules</h3>
                                    <p>Display popups on different positions on the screen. And make sure the user experience doesn’t get sabotaged at any point. Display slide-ins on the left or right side of the screen And floating bars at the top or bottom of your screen. Also, you have options to the floating bars while scrolling.</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center pt-5">
                            <Col md="6">
                                <div className="mb-4 ps-3 pe-5">
                                    <h3>Show On Page Load & Re-target Audience Triggers</h3>
                                    <p>Hate to make your visitors wait? Show popups on page load with Optinly. All you have to do is, slide the toggle button and your visitors will see popups the moment they enter your web page. Also, you can retarget audience by displaying popups after a set number of days.</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon6} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
            </>
        )
    }
}

export default HomePopupFeatures
