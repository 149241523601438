import React from 'react'
import Layout from '../../components/layout'
import Metatags from "../../components/Metatags"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
//import home page components
import GetStarted from '../../components/getstarted'
import Banner from '../../components/Banner';
import HomeIntegrationCompanies from '../../constants/Home/IntegrationCompanies';
import HomeCounter from '../../constants/Home/homeCounter';
import HomePopupFeatures from '../../constants/MultiplePopupTrigger/HomePopupFeatures';
import HomePopupClients from '../../constants/EmailPopups/HomePopupClients';
import SwiperSlider from '../../components/SwiperSlider';
import Bg from '../../images/background-1.png';
import SliderImg1 from '../../images/mike.png';
import SliderImg2 from '../../images/jockbrocas.png';
import SliderImg3 from '../../images/caseyjones.png';

const Title = () => (
    <><h1><span className="style-highlight">Multiple Popup Triggering Options.
        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg>
    </span> Display Web Popups When It Matters Most.</h1></>
);

const Slides = [
    {
        content: `
            <div className="swiper-slide-contents">
                <div className="elementor-slide-heading">"Was Not a fan of Popups, Until Now!"</div>
                <div className="elementor-slide-description">What I like here is, there is no subscriber limit and their templates are amazing. It also has a dashboard to monitor conversions/campaigns for all my website/clients!<br/><br/>
                <b>Mike</b> - mrdzyn.com 
                </div>
            </div>`,
        img: SliderImg1
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">"I Ditched Optinmonster&#8203;"</div><div className="elementor-slide-description">This has been the answer to my needs. I have jumped all in and now that I have played with it, I am slowly moving all my sites over to Optinly.<br><br><b>JockBrocas</b> - jockbrocas.com</div>
            </div>`,
        img: SliderImg2
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">Love Optinly!</div><div className="elementor-slide-description">One word pretty well sums it up - Awesome!<br><br>
            <b>Casey Jones</b> - www.cjco.com.au</div></div>`,
        img: SliderImg3
    },
]

const SwiperSlides = () => (
    <div className="position-relative">
        <SwiperSlider slidesPerView={1} slides={Slides} spaceBetween={0} slideDeley={5000} />
    </div>
);

const SpinTheWheelPopups = () => (
    <Layout className="landing-page">
        <Metatags
            title="Multiple Popup Triggers with Optinly"
            description="Use Optinly’s multiple popup display rules to trigger popups to increase sales, capture leads, and drive conversion on your website."
            keywords={[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url="https://www.optinly.com"
            pathname="/"
        />
        <Banner title={<Title />} description="Never miss an opportunity to increase sales/capture leads/drive conversion on your website. Use Optinly’s multiple popup display rules to triggers popups when required." actionUrl="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" actionText={"Create Popup Campaign"} slider={<SwiperSlides />} bgImg={Bg} />

        <HomeIntegrationCompanies />
        <HomeCounter />
        <HomePopupFeatures />
        <div className="py-5 faq-section">
            <div className="container py-3">
                <div className="mb-5 text-center hero-content wow fadeIn">
                    <h2>Frequently Asked <span className="style-highlight"> Popup Display Rules
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg> Questions</span>
                    </h2>
                </div>
                <Accordion allowZeroExpanded>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Can I use multiple popup triggering options at the same time?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Yes. Optinly allows you to add multiple popup display rules to a single campaign. For example, you can choose to show a popup on page load and choose specific URLs for displaying as well.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Is exit intent popup triggering option effective?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Exit intent trigger is one of the most widely used popup display rules by websites and eCommerce owners today. Displaying last-minute exciting offers/useful resources has always helped businesses capture email leads. Stats say that the average conversion rate of exit intent triggers is 4%.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Can I trigger popups on both mobile and desktops?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Yes. You can trigger popups on multiple devices with Optinly. You can use the toggle buttons to choose which devices you want to display popups. You can either display on all three devices or try out different combinations.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Can I exclude popups from being displayed on a particular page?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Optinly’s page-based popup display rules allow you to exclude popups from displayed on specific pages. You can use the <strong>“Does Not Contain”</strong> condition and exclude popups from being displayed on specific pages.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Can I create unlimited popup campaigns with Optinly?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Optinly allows you to create unlimited popup campaigns with no limit on the number of pageviews/sessions. You can also add multiple domains to your Optinly account.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>

        <HomePopupClients />
        <GetStarted />

    </Layout>
);

export default SpinTheWheelPopups;